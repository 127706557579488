<template>
  <div>
    <!--    <el-radio-group v-for='item of typeList' @change='serchs(item.id)' disabled='true' v-model='labelPosition' size='small'>-->
    <!--      <el-radio-button style='margin-right: 10px;' :label='item.id'>{{ item.value }}</el-radio-button>-->
    <!--    </el-radio-group>-->

    <el-button icon="el-icon-plus" type="primary" @click="userAddData"
    >新增
    </el-button
    >
    <div style="margin: 10px;"></div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column
        align="center"
        header-align="center"
        label="序号"
        type="index"
        width="60"
      >
      </el-table-column>
      <el-table-column
        align="left"
        header-align="left"
        label="	标题"
        prop="title"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        label="	类型名称"
        prop="typeName"
        width="150"
      >
      </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        label="	创建时间"
        prop="createDatetime"
        width="200"
      >
      </el-table-column>
            <el-table-column
              prop="sortOrder"
              header-align="center"
              align="center"
              label="排序"
              width="200"
            >
              <template slot-scope="scope">
                <el-button
                  icon="el-icon-bottom"
                  size="small"
                  @click="handleSort(scope.row.id, 'down')"
                ></el-button>
                <el-button
                  icon="el-icon-top"
                  size="small"
                  @click="handleSort(scope.row.id, 'up')"
                ></el-button>
              </template>
            </el-table-column>
      <el-table-column
        align="center"
        header-align="center"
        label="操作"
        width="240"
      >
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            size="mini"
            type="primary"
            @click="handleEdit(scope.row.id)"
          >编辑
          </el-button>
          <el-button
            icon="el-icon-delete"
            size="mini"
            type="danger"
            @click="handleDelete(scope.row.id)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 10px; height: 32px; width: 100%"></div>
    <el-dialog
      :append-to-body="true"
      :title="this.title"
      :visible.sync="dialogFormVisible"
      top="5vh"
      width="800px"
      @close="close('formName')"
    >
      <el-form ref="formName" :model="form" :rules="rules" style="width: 100%">
        <el-form-item class="form" label="标题：" prop="title">
          <el-input
            v-model="form.title"
            class="formInput"
            placeholder="请输入标题"
          ></el-input>
        </el-form-item>
        <el-form-item class="form" label="类型名称：" prop="typeName">
          <el-input
            v-model="form.typeName"
            class="formInput"
            placeholder="请输入类型名称"
          ></el-input>
        </el-form-item>

        <el-form-item class="form error" label="内容：" prop="content">
          <vue-editor
            v-model="form.content"
            :editor-toolbar="customToolbar"
            class="formInput"
            style="    width: 180.2%;
    height: 150px;
    margin-left: 64px;
    margin-top: 1px;"
          ></vue-editor>
        </el-form-item>
        <el-form-item
          style="display: flex;flex-direction: row;justify-content: flex-end;margin: 10px 0;">
          <el-button type="primary" @click="formSave('formName')">确 定</el-button>
          <el-button @click="formCancel('formName')">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import {
  queryRotatePicturesList,
  rotatePicturesSoet,
  rotatePicturesSave,
  rotatePicturesDetail,
  rotatePicturesDelete,
  uploadFile,
  webServerHttpUrl,
  messageInfoupdate,
  rotateQueryList,
  messageInfoSave,
  aboutUsSort
} from "@/api/about";
import { VueEditor } from "vue2-editor";

export default {
  components: { VueEditor },
  data() {
    return {
      customToolbar: [
        // 富文本框配置文件
        ["bold", "italic", "color", "background", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "video", "code-block", "code", "link"],
        ["clean"]
      ],
      web: null, // 图片路径
      imgList: [], // 图片数据
      dialogImageUrl: "", // 图片显示的路径
      title: null, // 表单弹窗标题
      tableData: [],
      typeList: [],
      labelPosition: 1,
      recordsTotal: null, // 数据总数
      pageInfo: {},
      dialogFormVisible: false, // 表单弹框开关
      dialogVisible: false,
      folder: {
        folder: "rotatePictures"
      },
      form: {
        typeName: null,
        title: null,
        content: null
      },
      rules: {
        typeName: [
          { required: true, message: "请输入类型名称", trigger: "blur" }
        ],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }]
      }
    };
  },
  created() {
    // this.getList()
  },
  watch: {
    // $route(to, from) {
    //   if (to.path === '/institutionalInformation/rotatePictures/2') {
    //     this.labelPosition = 2
    //     this.getList()
    //   } else if (to.path === '/homePageSettings/rotatePictures/1') {
    //     this.labelPosition = 1
    //     this.getList()
    //   }
    // }
  },
  mounted() {
    // this.$nextTick(() => {
    //   if (this.$route.path === '/institutionalInformation/rotatePictures/2') {
    //     this.labelPosition = 2
    //     // this.getList()
    //   } else if (this.$route.path === '/homePageSettings/rotatePictures/1') {
    //     this.labelPosition = 1
    //     // this.getList()
    //   }
    // })
    this.getList();
  },
  methods: {
    //清空表单
    formData() {
      this.form.typeName = null;
      this.form.title = null;
      this.form.content = null;
    },
    indexMethod(index) {
      index = index + 1 * this.pageInfo.pageSize;
      return index;
    },
    serchs(id) {
      this.labelPosition = id;
      this.getList();
    },
    codeId(row) {
      if (this.typeList != null) {
        for (let i = 0; i < this.typeList.length; i++) {
          if (row.codeId == this.typeList[i].id) {
            return this.typeList[i].value;
          }
        }
      }
    },
    getList() {
       rotateQueryList().then(res => {
        this.tableData = res;
      });
    },
    // 排序
    handleSort(id, upOrDown) {
      aboutUsSort(id, upOrDown).then(() => {
        this.getList();
      });
    },
    // 弹框关闭事件
    close(formName) {
      this.$refs[formName].resetFields();
      if (this.$refs.upload != null) {
        this.$refs.upload.clearFiles();
      }
    },
    //保存按钮
    formSave(formName) {
      // if (this.labelPosition == 1) {
      //   this.from.codeId = 1
      // }
      this.form.codeId = this.labelPosition;
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm("您确定保存吗？", "信息提示", {
            cancelButtonClass: "btn-custom-cancel",
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
            .then(() => {
              console.log(this.form);
              if (this.form.id == null) {
                messageInfoSave(this.form).then(res => {
                  console.log(res);
                  this.$alert("保存成功！", "信息提示", {
                    confirmButtonText: "确定",
                    type: "success"
                  }).then(() => {
                    this.getList();
                    this.formData();
                    this.dialogFormVisible = false;
                    this.$refs[formName].resetFields();
                  });
                });
              } else {
                messageInfoupdate(this.form).then(res => {
                  console.log(res);
                  this.$alert("保存成功！", "信息提示", {
                    confirmButtonText: "确定",
                    type: "success"
                  }).then(() => {
                    this.getList();
                    this.formData();
                    this.dialogFormVisible = false;
                    this.$refs[formName].resetFields();
                  });
                });
              }
            })
            .catch(() => {
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //  分页
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      this.getList();
    },
    //新增
    userAddData() {
      this.imgList = [];
      this.title = "新增";
      this.formData();
      this.dialogFormVisible = true;
    },
    //编辑
    handleEdit(id) {
      this.imgList = [];
      this.title = "编辑";
      rotatePicturesDetail(id).then(res => {
        this.form = res;
        this.form.id = res.id;
        this.imgList.push({
          url: res.picPathHttpUrl
        });
        this.dialogFormVisible = true;
      });
    },
    //删除
    handleDelete(id) {
      this.$confirm("您确定删除吗？", "信息提示", {
        cancelButtonClass: "btn-custom-cancel",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          rotatePicturesDelete(id).then(res => {
            console.log(res);
            this.$alert("删除成功！", "信息提示", {
              confirmButtonText: "确定",
              type: "success"
            }).then(() => {
              this.getList();
            });
          });
        })
        .catch(() => {
        });
    },
    // 取消
    formCancel(formName) {
      this.dialogFormVisible = false;
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style lang="less" scoped>
.home {
  margin: auto;
}

.form {
  width: 47.2%;
  display: inline-block;
  margin: 10px 10px;
}

/deep/ .el-upload-list__item-actions {
  width: 200px;
  height: 100px;
}

.form1 {
  width: 100%;
  display: inline-block;
  margin: 13px 10px;
}

.formInput {
  width: 67%;
  /*margin-left: 16px;*/
}

/deep/ .el-dialog__body {
  margin-top: -19px;
}

/deep/ .ql-editor {
  min-height: 140px !important;
}

/deep/ .el-upload--picture-card {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  line-height: 80px;
  vertical-align: top;
}

/deep/ .el-upload--picture-card {
  width: 70px;
  height: 70px;
  margin-top: 37px;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 300px;
  height: 150px;
}

/deep/ .el-upload-list__item-actions {
  width: 300px;
  height: 150px;
}

/deep/ #quill-container {
  height: 145px;
}

/deep/ [data-v-0dd82d4c] .ql-editor {
  min-height: 100px !important;
}

/*去除upload组件过渡效果*/
/deep/ .el-upload-list__item {
  transition: none !important;
}
</style>
