import {get,post} from "../http/index";

export const rotateQueryList = () => get('/aboutUs/queryList')
//删除
export const rotatePicturesDelete = (params) => get('/aboutUs/delete/' + params);
//排序
export const rotatePicturesSoet = (id, upOrDown) =>get('/rotatePictures/sort/' + upOrDown + "/" + id);
//详情
export const rotatePicturesDetail = (params) => get('/aboutUs/detail/' + params);
//保存新增
export const messageInfoSave = (params) => post('/aboutUs/save',params)
// 编辑数据保存
export const messageInfoupdate = (params) => post('/aboutUs/update',params)

// 图片上传集合
export const uploadFile = (params, folder) => post('/fileProcessing/uploadFile', params, folder)

// 获取图片链接
export const webServerHttpUrl = () => get('/fileProcessing/webServerHttpUrl')

//排序
export const aboutUsSort = (id, upOrDown) =>get('/aboutUs/sort/' + upOrDown + "/" + id);
